export default function ImportCDN(src: string, libName: string) {
  return new Promise((resolve) => {
    const dom = document.createElement('script');
    dom.src = src;
    dom.type = 'text/javascript';
    dom.onload = () => {
      resolve(libName ? (window as any)[libName] : undefined);
    };
    document.head.appendChild(dom);
  });
}

export function ImportCSS(href: string) {
  return new Promise((resolve) => {
    const dom = document.createElement('link');
    dom.href = href;
    dom.rel = 'stylesheet';
    dom.onload = () => {
      resolve(true);
    };
    document.head.appendChild(dom);
  });
}
