import { Link } from 'react-router-dom';

export default function Scene4() {
  return (
    <>
      <h2>场景 8</h2>
      <ul>
        {Array.from({ length: 7 }).map((_, index) => {
          return (
            <li key={index}>
              <Link to={'demo' + (index + 1)}>demo {index + 1}</Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}
