import RandExp from 'randexp';
import React, { useCallback, useState } from 'react';
import './index.scss';

//WapvruItMrvRozDF0DYvl

const blackList = [
  'ProseMirror',
  'ProseMirror-focused',
  'semi-select-option-focused', // 官网sass
  'ant-select-open',
  'ant-tooltip-open',
  'active',
  'hover',
  'selected',
  // 表单校验样式
  'is-',
  'in-range',
  'fz_selecto_container',
  'fz_snappable',
  'focused',
];
const RegList = [/el-table_\d{1,}_column_\d{1,}/, /color-\d+/, /ng-tns-c\d+-\d+/, /Button__Wrapper/, /x-btn/];
//根据正则表达式生成随机字符串
function getRandExpString(reg: RegExp) {
  return new RandExp(reg).gen();
}

export default function Scene6() {
  const [count, setCount] = useState(0);
  const handleClick = useCallback((num: number) => {
    setCount((prev) => prev + num);
  }, []);
  const toggleClass = useCallback((e: React.MouseEvent) => {
    (e?.target as Element).classList?.toggle('btn-hover');
    console.log('toggleClass');
  }, []);
  return (
    <>
      <h5>测试页面 6</h5>
      <h5>class 黑名单</h5>
      <div>
        count: <span>{count}</span>
      </div>
      <MemoComponent toggleClass={toggleClass} handleClick={handleClick} />
    </>
  );
}

function Component({
  toggleClass,
  handleClick,
}: {
  toggleClass: (e: React.MouseEvent) => void;
  handleClick: (num: number) => void;
}) {
  return (
    <>
      <div>
        {blackList.map((item, index) => {
          const className = `${item}_${Math.floor(Math.random() * 1000)}`;
          return (
            <button
              onClick={() => handleClick(index + 1)}
              key={index}
              className={className}
              // onMouseEnter={toggleClass}
              // onMouseLeave={toggleClass}
            >
              {item}
            </button>
          );
        })}
      </div>

      <br />
      <p>符合正则：{RegList.toString()}</p>
      <div>
        {RegList.map((item, index) => {
          const className = getRandExpString(item);
          return (
            <button
              onClick={() => handleClick(2 * (index + 1))}
              key={index}
              className={className}
              // onMouseEnter={toggleClass}
              // onMouseLeave={toggleClass}
            >
              {item.toString()}
            </button>
          );
        })}
      </div>
      <br />
      <p>固定 class</p>
      <div>
        <button
          onClick={() => handleClick(32)}
          className="class-1"
          onMouseEnter={toggleClass}
          onMouseLeave={toggleClass}
        >
          Hover 变红
        </button>
        <button onClick={() => handleClick(33)} className="class-2">
          button 2
        </button>
        <button onClick={() => handleClick(34)} className="class-3">
          button 3
        </button>
      </div>
    </>
  );
}

const MemoComponent = React.memo(Component);
