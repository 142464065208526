import { useState } from 'react';

export default function Scene4() {
  const [count, setCount] = useState(0);
  const handlePointerUp = () => {
    setCount((prev) => prev + 1);
    console.log('pointerUp');
  };
  const handlePointerDown = () => {
    setCount((prev) => prev + 1);
    console.log('pointerDown');
  };
  const handleMouseUp = () => {
    setCount((prev) => prev + 1);
    console.log('mouseUp');
  };
  const handleMouseDown = () => {
    setCount((prev) => prev + 1);
    console.log('mouseDown');
  };
  const handleClick = () => {
    setCount((prev) => prev + 1);
    console.log('click');
  };

  return (
    <>
      <h2>场景 4</h2>
      <div>
        count: <span>{count}</span>
      </div>
      <div>
        <h1>鼠标事件1</h1>
        <h5>模拟触发时，在采用组合键触发时，功能是否正常</h5>
        <button id="onlyBtnPointer" onPointerUp={handlePointerUp} onPointerDown={handlePointerDown}>
          只有 pointerDown pointerUp 事件
        </button>
        <button id="onlyBtnMouse" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
          只有 mouseDown mouseUp 事件
        </button>
        <div
          style={{
            padding: '20px',
          }}
          id="onlyBtnClick"
          onClick={handleClick}
          onMouseUp={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onPointerUp={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // console.log('pointerUp');
          }}
        >
          <button
            // onMouseUp={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            // }}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            // onPointerUp={(e) => {
            //   e.preventDefault();
            //   e.stopPropagation();
            // }}
            onPointerDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            click
          </button>
          {/* 只有 click 事件 */}
        </div>
      </div>
      <div>
        <h1>鼠标事件2</h1>
        <button
          id="btnPointerMouseClick"
          onClick={handleClick}
          onPointerUp={handlePointerUp}
          onPointerDown={handlePointerDown}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          同时 pointer mouse click 事件 +5
        </button>
        <button
          id="btnPointerMouse"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onPointerUp={handlePointerUp}
          onPointerDown={handlePointerDown}
        >
          同时 pointer mouse 事件 +4
        </button>
        <button
          id="btnPointerClick"
          onPointerUp={handlePointerUp}
          onPointerDown={handlePointerDown}
          onClick={handleClick}
        >
          同时 pointer click 事件 +3
        </button>
        <button id="btnMouseClick" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onClick={handleClick}>
          同时 mouse click 事件 +3
        </button>
      </div>
    </>
  );
}
