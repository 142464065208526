import { useState } from 'react';

//ImeanSDK.replay({
// "id":"p2VfV4sAay9Ms5QMu8Ant"
// })

function Scene3() {
  const [count, setCount] = useState(0);
  const handleClick = (num: number) => {
    setCount((prev) => prev + num);
  };
  return (
    <div>
      测试页面3
      <div>
        <h1>场景 3</h1>
        <h5>回放时，若最后一步将触发步骤关闭，功能是否正常</h5>
        <h5>则需要手动禁止click监听，因为watch会被销毁</h5>
        <div>
          count: <span>{count}</span>
        </div>
        <br />
        <button onClick={() => handleClick(1)}>+1</button>
        <br />
        <button onClick={() => handleClick(2)}>+2</button>
        <br />
        <button onClick={() => handleClick(3)}>+3</button>
        <br />
        <button onClick={() => handleClick(4)}>+4</button>
        <br />
        <button onClick={() => handleClick(5)}>+5</button>
        <br />
      </div>
    </div>
  );
}

export default Scene3;
