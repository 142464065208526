import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import Layout from './components/Layout';
import AD from './pages/Activity';
import Bin from './pages/Bin';
import Editor from './pages/Editor';
import JumpTab from './pages/JumpTab';
import Login from './pages/Login';
import Scroll from './pages/Scroll';
import User from './pages/User';
import Welcome from './pages/Welcome';

import Test from './pages/Test';
import Scene1 from './pages/Test/Scene1';
import Scene10 from './pages/Test/Scene10';
import Scene11 from './pages/Test/Scene11';
import Scene12 from './pages/Test/Scene12';
import Scene3 from './pages/Test/Scene3';
import Scene4 from './pages/Test/Scene4';
import Scene5 from './pages/Test/Scene5';
import Scene6 from './pages/Test/Scene6';
import Scene7 from './pages/Test/Scene7';
import Scene8 from './pages/Test/Scene8';
import Scene9 from './pages/Test/Scene9';

import Scene2, * as Scene2_Demos from './pages/Test/Scene2/index';
import * as Scene7_Demos from './pages/Test/Scene7/Demo';
import * as Scene8_Demos from './pages/Test/Scene8/demo';
import { SdkRoutes } from './SdkRouter';

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        {SdkRoutes()}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Welcome />} />
          <Route path="user" element={<User />} />
          <Route path="bin" element={<Bin />} />
          <Route path="ad" element={<AD />} />
          <Route path="editor" element={<Editor />} />
          <Route path="scroll" element={<Scroll />} />
          <Route path="JumpTab1" element={<JumpTab />} />
          <Route path="JumpTab2" element={<JumpTab />} />
          <Route path="test">
            <Route element={<Test />} index />
            <Route path="scene1" element={<Scene1 />} />
            <Route path="scene2">
              <Route index element={<Scene2 />} />
              <Route path="demo1" element={<Scene2_Demos.Demo1 />} />
              <Route path="demo2" element={<Scene2_Demos.Demo2 />} />
              <Route path="demo3" element={<Scene2_Demos.Demo3 />} />
              <Route path="demo4" element={<Scene2_Demos.Demo4 />} />
            </Route>

            <Route path="scene3" element={<Scene3 />} />
            <Route path="scene4" element={<Scene4 />} />
            <Route path="scene5" element={<Scene5 />} />
            <Route path="scene6" element={<Scene6 />} />
            <Route path="scene7">
              <Route index element={<Scene7 />} />
              <Route path="demo1" element={<Scene7_Demos.Demo1 />} />
            </Route>
            {/* 鼠标事件 */}
            <Route path="scene8">
              <Route element={<Scene8 />} index />
              <Route path="demo1" element={<Scene8_Demos.Demo1 />} />
              <Route path="demo2" element={<Scene8_Demos.Demo2 />} />
              <Route path="demo3" element={<Scene8_Demos.Demo3 />} />
              <Route path="demo4" element={<Scene8_Demos.Demo4 />} />
              <Route path="demo5" element={<Scene8_Demos.Demo5 />} />
              <Route path="demo6" element={<Scene8_Demos.Demo6 />} />
              <Route path="demo7" element={<Scene8_Demos.Demo7 />} />
            </Route>
            <Route path="scene9" element={<Scene9 />} />
            <Route path="scene10" element={<Scene10 />} />
            <Route path="scene11" element={<Scene11 />} />
            <Route path="scene12" element={<Scene12 />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
