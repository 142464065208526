import RandExp from 'randexp';
import React, { useCallback, useState } from 'react';

//nbcN_pL1zofaS9-uXvjDT

const blackList = ['cascader-menu', 'mantine-', 'rc-tabs', 'rc_tabs', 'rc_select_', 'rt_rt_', 'ext-gen'];
/**
 ** 生成一个用不重复的ID
 * @param { Number } randomLength
 **/
function getUuiD(prefix: string, randomLength: number) {
  return prefix + Number(Math.random().toString().substr(2, randomLength) + Date.now()).toString(36);
}

function Scene5() {
  const [count, setCount] = useState(0);
  const handleClick = useCallback((num: number) => {
    setCount((prev) => prev + num);
  }, []);
  return (
    <div>
      <h1>场景 5</h1>
      <h5>随机id</h5>
      <div>
        count: <span>{count}</span>
      </div>
      <MemoB handleClick={handleClick} />
    </div>
  );
}

function B({ handleClick }: any) {
  return (
    <>
      <div>
        <h5>名单上的随机id</h5>
        {blackList.map((item, index) => {
          const randomId = getUuiD(item, 2);
          return (
            <button id={randomId} key={index} onClick={() => handleClick(index + 1)}>
              {'名单上的随机id_' + index}
            </button>
          );
        })}

        <h5>以随机id结尾的</h5>
        {Array.from({ length: 5 }).map((item, index) => {
          const randNum = new RandExp(/\d{3,}$/).gen();
          const randomId = 'random_' + '_' + randNum;
          return (
            <button key={index} id={randomId} onClick={() => handleClick(2 * (index + 1))}>
              {'随机id结尾_' + index}
            </button>
          );
        })}
      </div>
      <br />
      <h5>其他随机id</h5>
      {Array.from({ length: 5 }).map((item, index) => {
        const randomId = getUuiD('', 2);
        return (
          <button key={index} id={randomId} onClick={() => handleClick(3 * (index + 1))}>
            {'随机id_' + index}
          </button>
        );
      })}
      <br />
      <h5>固定id</h5>
      {Array.from({ length: 5 }).map((item, index) => {
        return (
          <button key={index} id={'id_' + index} onClick={() => handleClick(4 * (index + 1))}>
            {'id_' + index}
          </button>
        );
      })}
    </>
  );
}

const MemoB = React.memo(B);

export default Scene5;
