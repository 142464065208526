import React, { useState } from 'react';

const EventType = {
  pointerDown: 'onPointerDown',
  pointerUp: 'onPointerUp',
  mouseDown: 'onMouseDown',
  mouseUp: 'onMouseUp',
  click: 'onClick',
};

function Demo1() {
  const [arr, setArr] = useState<string[]>([]);
  const handlePointerDown = (e: React.PointerEvent<HTMLButtonElement>) => {
    setArr((prev) => [...prev, EventType.pointerDown]);
  };
  const handlePointerUp = (e: React.PointerEvent<HTMLButtonElement>) => {
    setArr((prev) => [...prev, EventType.pointerUp]);
  };

  const props = {
    [EventType.pointerDown]: handlePointerDown,
    [EventType.pointerUp]: handlePointerUp,
  };

  return (
    <>
      <div>
        arr: <span>{arr.join(' ')}</span>
      </div>
      {Array.from({ length: 5 }).map((_, index) => {
        return (
          <button {...props} key={index}>
            只有 pointerDown pointerUp事件
          </button>
        );
      })}
    </>
  );
}

function Demo2() {
  const [arr, setArr] = useState<string[]>([]);
  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    setArr((prev) => [...prev, EventType.mouseDown]);
  };
  const handleMouseUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    setArr((prev) => [...prev, EventType.mouseUp]);
  };

  const props = {
    [EventType.mouseDown]: handleMouseDown,
    [EventType.mouseUp]: handleMouseUp,
  };

  return (
    <>
      <div>
        arr: <span>{arr.join(' ')}</span>
      </div>
      {Array.from({ length: 5 }).map((_, index) => (
        <button {...props}>只有 mouseDown mouseUp</button>
      ))}
    </>
  );
}

function Demo3() {
  const [arr, setArr] = useState<string[]>([]);
  const handleMouseDown = (e: React.MouseEvent) => {
    setArr((prev) => [...prev, EventType.mouseDown]);
  };
  const handlePointerDown = (e: React.MouseEvent) => {
    setArr((prev) => [...prev, EventType.pointerDown]);
  };

  const props = {
    [EventType.pointerDown]: handlePointerDown,
    [EventType.mouseDown]: handleMouseDown,
  };

  return (
    <>
      <div>
        arr: <span>{arr.join(' ')}</span>
      </div>
      {Array.from({ length: 5 }).map((_, index) => (
        <button {...props}>同时有 pointerDown mouseDown 事件</button>
      ))}
    </>
  );
}

function Demo4() {
  const [arr, setArr] = useState<string[]>([]);
  const handleMouseUp = (e: React.MouseEvent) => {
    setArr((prev) => [...prev, EventType.mouseUp]);
  };
  const handlePointerUp = (e: React.PointerEvent) => {
    setArr((prev) => [...prev, EventType.pointerUp]);
  };

  const props = {
    [EventType.pointerUp]: handlePointerUp,
    [EventType.mouseUp]: handleMouseUp,
  };
  return (
    <>
      <div>
        arr: <span>{arr.join(' ')}</span>
      </div>
      {Array.from({ length: 5 }).map((_, index) => (
        <button {...props}>同时有 pointerUp mouseUp 事件</button>
      ))}
    </>
  );
}

function Demo5() {
  const [arr, setArr] = useState<string[]>([]);
  const handleClick = (e: React.MouseEvent) => {
    setArr((prev) => [...prev, EventType.click]);
  };

  const props = {
    [EventType.click]: handleClick,
  };
  return (
    <>
      <div>
        arr: <span>{arr.join(' ')}</span>
      </div>
      {Array.from({ length: 5 }).map((_, index) => (
        <button {...props}>只有 click 事件</button>
      ))}
    </>
  );
}

//模拟触发时，当元素禁用了事件，功能是否正常
function Demo6() {
  return (
    <>
      <input
        onMouseUp={() => {
          console.log('onMouseUp');
        }}
        type="text"
        disabled
        onClick={() => {
          console.log('onClick');
        }}
        value="input disabled"
      />
      <br />
      <input
        onMouseUp={() => {
          console.log('onMouseUp');
        }}
        type="range"
        disabled
        onClick={() => {
          console.log('onClick');
        }}
        value="input disabled"
      />
      <br />
      <input
        onMouseUp={() => {
          console.log('onMouseUp');
        }}
        type="radio"
        disabled
        onClick={() => {
          console.log('onClick');
        }}
        value="input disabled"
      />
      <br />
      <input
        onMouseUp={() => {
          console.log('onMouseUp');
        }}
        type="checkbox"
        disabled
        onClick={() => {
          console.log('onClick');
        }}
        value="input disabled"
      />
      <br />
      <input
        onMouseUp={() => {
          console.log('onMouseUp');
        }}
        type="file"
        disabled
        onClick={() => {
          console.log('onClick');
        }}
      />
    </>
  );
}

//模拟触发时，a标签在采用组合键触发时，功能是否正常

function Demo7() {
  const [arr, setArr] = useState([]);
  return (
    <>
      <div>
        <h5>使用组合事件触发a标签</h5>
        arr: <span>{arr.join(' ')}</span>
      </div>
      {/* <a
        onMouseDown={(e) => {
          setArr((prev) => [...prev, 'onMouseDown']);
          // mousedown 打开新页面不会触发 click
          window.open('https://www.google.com');
        }}
        onClick={(e) => {
          setArr((prev) => [...prev, 'onClick']);
          e.preventDefault();
        }}
        href="#"
      >
        link
      </a>
      <br /> */}
      <a
        onMouseUp={(e) => {
          setArr((prev) => [...prev, 'onMouseUp']);
          window.open('https://www.google.com');
        }}
        onClick={(e) => {
          setArr((prev) => [...prev, 'onClick']);
          e.preventDefault();
        }}
        href="#"
      >
        link
      </a>
      {/* <br />
      <a
        onPointerDown={(e) => {
          setArr((prev) => [...prev, 'onPointerDown']);
          // pointerDown 打开新页面不会触发 click
          window.open('https://www.google.com');
        }}
        onClick={(e) => {
          setArr((prev) => [...prev, 'onClick']);
          e.preventDefault();
        }}
        href="#"
      >
        link
      </a> */}
      <br />
      <a
        onPointerUp={(e) => {
          setArr((prev) => [...prev, 'onPointerUp']);
          window.open('https://www.google.com');
        }}
        onClick={(e) => {
          setArr((prev) => [...prev, 'onClick']);
          e.preventDefault();
        }}
        href="#"
      >
        link
      </a>
    </>
  );
}

export { Demo1, Demo2, Demo3, Demo4, Demo5, Demo6, Demo7 };
