import storage from '@/storage';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { SafeJsonType } from 'safe-json-type';
import './index.scss';
export default function Layout() {
  const navigate = useNavigate();
  const [userInfo] = useState(() => {
    try {
      return SafeJsonType.parse(storage.get('userInfo') || '');
    } catch (error) {
      return {};
    }
  });

  const menus = [
    { label: '首页', path: '/' },
    { label: '用户管理', path: '/user' },
    { label: '回收站', path: '/bin' },
    { label: '活动', path: '/ad' },
    { label: '编辑器', path: '/editor' },
    { label: '页面滚动', path: '/scroll' },
    { label: '跳转页面', path: '/JumpTab1' },
    { label: '测试页面', path: '/test' },
    { label: 'SDK测试页面', path: '/sdk-pages' },
  ];

  return (
    <div className="page-welcome">
      <div>欢迎用户：</div>
      <pre>{JSON.stringify(userInfo, null, 2)}</pre>
      <div
        className="logout menu"
        onClick={() => {
          storage.del('userInfo');
          navigate('/login');
        }}
      >
        退出登录
      </div>

      <div className="menus">
        {menus.map((menu) => {
          return (
            <div className="menu" key={menu.path}>
              <Link to={menu.path}>{menu.label}</Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
