import { useState } from 'react';
import { useNavigate } from 'react-router';
import { SafeJsonType } from 'safe-json-type';
import storage from '../../storage';
import './index.scss';
export default function Login() {
  const navigate = useNavigate();

  const [baseUrl, setBaseUrl] = useState(storage.get('baseUrl') || 'https://useimean.com');
  const [sdkUrl, setSdkUrl] = useState(storage.get('sdkUrl') || 'https://useimean.com/sdk');
  const [projectId, setProjectId] = useState(storage.get('projectId') || 'VYzjxl6--9nBBTR_-aj9E');

  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('fuchuan');
  const [userType, setUserType] = useState('admin');
  const [isNew, setIsNew] = useState(false);
  const [age, setAge] = useState('18');
  const [date, setDate] = useState('2022-07-18');

  return (
    <div className="page-login">
      <div className="form">
        <div className="form-item">
          <div className="label">环境地址 ：</div>
          <input
            type="text"
            placeholder="环境"
            value={baseUrl}
            onChange={(e) => {
              setBaseUrl(e.target.value);
              setSdkUrl(e.target.value + '/sdk');
            }}
          />
        </div>
        <div className="form-item">
          <div className="label">SDK地址 ：</div>
          <input
            type="text"
            placeholder="环境"
            value={sdkUrl}
            onChange={(e) => {
              setSdkUrl(e.target.value);
            }}
          />
        </div>
        <div className="form-item">
          <div className="label">项目id ：</div>
          <input
            type="text"
            placeholder="项目id"
            value={projectId}
            onChange={(e) => {
              setProjectId(e.target.value);
            }}
          />
        </div>
        <div className="form-item"></div>
        <div className="form-item">
          <div className="label">自定义用户id 字符串：</div>
          <input
            type="text"
            placeholder="自定义用户id 字符串"
            value={userId}
            onChange={(e) => {
              setUserId(e.target.value);
            }}
          />
        </div>
        <div className="form-item"></div>
        <div className="form-item">
          <div className="label">用户名 字符串：</div>
          <input
            type="text"
            placeholder="用户名 字符串"
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
        </div>
        <div className="form-item">
          <div className="label">用户类型 字符串：</div>
          <input
            type="text"
            placeholder="用户类型 字符串"
            value={userType}
            onChange={(e) => {
              setUserType(e.target.value);
            }}
          />
        </div>
        <div className="form-item">
          <div className="label">是否新用户 布尔：</div>
          <input
            type="checkbox"
            name="isNew"
            placeholder="是否新用户 布尔"
            checked={isNew}
            onChange={(e) => {
              console.log(e);
              setIsNew(e.target.checked);
            }}
          />
        </div>
        <div className="form-item">
          <div className="label">年龄 数字：</div>
          <input
            type="number"
            placeholder="年龄 数字"
            value={age}
            onChange={(e) => {
              console.log(e.target.value);
              setAge(e.target.value);
            }}
          />
        </div>
        <div className="form-item">
          <div className="label">注册日期 日期：</div>
          <input
            type="date"
            placeholder="注册日期 日期"
            value={date}
            onChange={(e) => {
              console.log(e.target.value);
              setDate(e.target.value);
            }}
          />
        </div>
        <div className="form-item">
          <button
            onClick={() => {
              const u = {
                userId,
                userName,
                userType,
                isNew,
                age: parseInt(age),
                date: new Date(date),
              };

              storage.set('userInfo', SafeJsonType.stringify(u));

              storage.set('baseUrl', baseUrl);
              storage.set('sdkUrl', sdkUrl);
              storage.set('projectId', projectId);

              navigate('/');
            }}
          >
            登录
          </button>
        </div>
      </div>
    </div>
  );
}
