import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './polyfill/globalThis';

import eruda from 'eruda';

if (localStorage.getItem('debug') === 'true') {
  eruda.init();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// export {};

// if (!(window as any).ImeanSDK) {
//   console.log("ImeanSDK is not defined");
// }
// console.log("111");
// try {
//   console.log(1 / 0);
//   throw new Error("Error");
// } catch {
//   console.log("123123123");
// }
