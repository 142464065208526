import { EditorContent, useEditor } from '@tiptap/react';
import './index.scss';
// import Document from "@tiptap/extension-document";
// import Paragraph from "@tiptap/extension-paragraph";
// import Text from "@tiptap/extension-text";
// import BubbleMenu from "@tiptap/extension-bubble-menu";
import StarterKit from '@tiptap/starter-kit';
export default function Layout() {
  const editor = useEditor({
    extensions: [StarterKit],
    content: '<p>Hello World</p>',
  });

  return (
    <div className="page-editor">
      <h1>Editor</h1>
      <EditorContent editor={editor} />
    </div>
  );
}
