import './index.scss';

export default function Layout() {
  return (
    <div className="page-JumpTab">
      <div
        onClick={() => {
          window.open('/JumpTab1');
        }}
      >
        跳转到1
      </div>
      <div>
        <br />
        <br />
        <br />
      </div>
      <div
        onClick={() => {
          window.open('/JumpTab2');
        }}
      >
        跳转到2
      </div>
    </div>
  );
}
