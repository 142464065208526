import { FunctionComponent, IframeHTMLAttributes, PropsWithChildren, useState } from 'react';
import { createPortal } from 'react-dom';

// ImeanSDK.replay({
// "id":"WMQRWkMuo1iCeWSpyv-LX"
// })

// iframe 渲染組件
function Scene1() {
  return (
    <div>
      <h1>场景 3</h1>
      <h5>回放时，点击事件，并且下一步另一个域名，并且是在iframe中，功能是否正常</h5>
      {/* <iframe src="https://www.useimean.com" width={800} height={800}></iframe> */}
      <CustomIframe title="test" height="500px" width="500px">
        <div>
          <a target="_self" href="https://www.useimean.com">
            <button>iframe 内跳转</button>
          </a>
          <a href="https://www.useimean.com" target="_blank">
            <button>新窗口跳转</button>
          </a>
        </div>
      </CustomIframe>
      <iframe src="https://www.useimean.com" frameBorder="1" height="500px" width="500px"></iframe>
    </div>
  );
}

const CustomIframe: FunctionComponent<PropsWithChildren<IframeHTMLAttributes<HTMLIFrameElement>>> = ({
  children,
  ...props
}) => {
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);

  const mountNode = contentRef?.contentWindow?.document.body;

  return (
    <iframe {...props} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

export default Scene1;
