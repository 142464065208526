import { lazy, Suspense } from 'react';
import { NavLink, Route } from 'react-router-dom';

export function SdkRoutes() {
  const pages = import.meta.glob('./sdk-pages/**/*.tsx');
  const routes: { path: string; page: any; module: string }[] = [];

  for (const path in pages) {
    let [, , moduleName, fileName] = path.split('/');
    const [pageName, tsxSuffix] = fileName.split('.tsx');
    moduleName = moduleName.replace(/\./, '-');

    if (!tsxSuffix) {
      routes.push({
        path: `${moduleName}/${pageName}`,
        module: moduleName,
        page: pages[path],
      });
    }
  }

  return (
    <Route path="/sdk-pages">
      <Route
        index
        element={
          <ul>
            {routes.map((route, index) => {
              const prev = routes[index - 1];
              const divider = prev?.module !== route.module ? true : false;
              return (
                <li key={route.path} style={{ marginTop: divider ? 20 : 0 }}>
                  <NavLink to={route.path}>{route.path}</NavLink>
                </li>
              );
            })}
          </ul>
        }
      />
      {routes.map((route) => {
        const Page = lazy(route.page);
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Suspense fallback={<div>loading</div>}>
                <Page />
              </Suspense>
            }
          />
        );
      })}
    </Route>
  );
}
