import Interactive from '../../components/Interactive';
import './index.scss';
export default function Layout() {
  return (
    <div className="page-activity">
      <h1>Activity</h1>
      <Interactive></Interactive>
    </div>
  );
}
