import { useState } from 'react';
import { eventTypeMapping } from './constant';

export default function Scene7() {
  const [count, setCount] = useState(0);

  const handleMouseUp = () => {
    setCount(count + 1);
    console.log(eventTypeMapping.mouseup);
  };
  const handleClick = () => {
    setCount(count + 1);

    console.log(eventTypeMapping.click);
  };
  const handleMouseOver = () => {
    setCount(count + 1);

    console.log(eventTypeMapping.mouseover);
  };
  const handleMouseDown = () => {
    setCount(count + 1);

    console.log(eventTypeMapping.mousedown);
  };
  const handlePointerUp = () => {
    setCount(count + 1);

    console.log(eventTypeMapping.pointerup);
  };
  const handlePointerDown = () => {
    setCount(count + 1);

    console.log(eventTypeMapping.pointerdown);
  };

  return (
    <>
      <h4>todo</h4>

      <h4>{count}</h4>
    </>
  );
}
