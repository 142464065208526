import { useState } from 'react';

//回放时，若最后一步将触发步骤关闭，功能是否正常
export default function Scene9() {
  const [arr, setArr] = useState<string[]>([]);
  const [onTop, setOnTop] = useState(false);
  const handleClick = () => {
    console.log('222');

    setArr((prev) => [...prev, 'click']);
  };
  const handleMouseDown = () => {
    setArr((prev) => [...prev, 'mouseDown']);
  };
  const handleMouseUp = () => {
    setArr((prev) => [...prev, 'mouseUp']);
  };
  const handlePointerDown = () => {
    setArr((prev) => [...prev, 'pointerDown']);
  };
  const handlePointerUp = () => {
    setArr((prev) => [...prev, 'pointerUp']);
  };

  return (
    <>
      <h5>down 的元素 和 up 的元素不一致</h5>
      <div>
        arr: <span>{arr.join(' ')}</span>
      </div>
      <div
        onPointerDown={() => {
          setOnTop(true);
          console.log('parent PointerDown');
          setArr((prev) => [...prev, 'parent PointerDown']);
        }}
        onPointerUp={() => {
          console.log('parent PointerUp');
          setArr((prev) => [...prev, 'parent PointerUp']);

          // setOnTop(false);
        }}
        style={{ backgroundColor: 'black', width: '300px', height: '300px', color: 'red', zIndex: 9 }}
      >
        <div
          onPointerDown={() => {
            console.log('child PointerDown');
            setArr((prev) => [...prev, 'child PointerDown']);
          }}
          onPointerUp={() => {
            console.log('child pointerUp');
            setArr((prev) => [...prev, 'child pointerUp']);
          }}
          style={{
            backgroundColor: 'grey',
            height: '300px',
            width: '300px',
            color: 'blue',
            zIndex: onTop ? 10 : -1,
            position: 'absolute',
          }}
        >
          up
        </div>
      </div>
    </>
  );
}
