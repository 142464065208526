import { Link } from 'react-router-dom';

const menus = Array.from({ length: 12 }).map((_, idx) => ({
  label: `scene${idx + 1}`,
  path: `scene${idx + 1}`,
}));

export default function Test() {
  return (
    <div className="page-user">
      <h1>这是测试界面</h1>
      {menus.map((menu) => {
        return (
          <div className="menu" key={menu.path}>
            <Link to={menu.path}>{menu.label}</Link>
          </div>
        );
      })}
    </div>
  );
}
