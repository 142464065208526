import { useState } from 'react';

//移动端关闭模拟触发

export default function Scene11() {
  const [arr, setArr] = useState<string[]>([]);
  const handleClick = () => {
    setArr((prev) => [...prev, 'click']);
  };
  const handleMouseDown = () => {
    setArr((prev) => [...prev, 'mouseDown']);
  };
  const handleMouseUp = () => {
    setArr((prev) => [...prev, 'mouseUp']);
  };
  const handlePointerDown = () => {
    setArr((prev) => [...prev, 'pointerDown']);
  };
  const handlePointerUp = () => {
    setArr((prev) => [...prev, 'pointerUp']);
  };

  return (
    <>
      <h5>移动端关闭模拟触发</h5>
      <div>
        arr: <span>{arr.join(' ')}</span>
      </div>
      <button onClick={handleClick} onMouseDown={handleMouseDown} onPointerDown={handlePointerDown}>
        click
      </button>
      <button onClick={handleClick} onMouseUp={handleMouseUp} onPointerUp={handlePointerUp}>
        click
      </button>
      <button onClick={handleClick} onMouseDown={handleMouseDown} onPointerUp={handlePointerUp}>
        click
      </button>
      <button onClick={handleClick} onMouseDown={handleMouseDown} onPointerDown={handlePointerDown}>
        click
      </button>
      <button onClick={handleClick} onMouseUp={handleMouseUp} onPointerDown={handlePointerDown}>
        click
      </button>
    </>
  );
}
