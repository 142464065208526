import React, { useState } from 'react';

function Demo1() {
  const [count, setCount] = useState<number>(0);
  const handleClick = (e: React.MouseEvent) => {
    setCount((prev) => prev + 1);
    // e.preventDefault();
  };
  const handleMouseUp = (e: React.MouseEvent) => {
    setCount((prev) => prev + 1);
    window.open('https://www.baidud.com');
  };
  const handleMouseDown = (e: React.MouseEvent) => {
    setCount((prev) => prev + 1);
    // e.preventDefault();
  };

  const handlePointerUp = (e: React.MouseEvent) => {
    setCount((prev) => prev + 1);
    // e.preventDefault();
  };
  const handlePointerDown = (e: React.MouseEvent) => {
    setCount((prev) => prev + 1);
    // e.preventDefault();
  };
  return (
    <>
      <div>
        count: <span>{count}</span>
      </div>
      <a onClick={handleClick} onMouseUp={handleMouseUp} onPointerDown={handlePointerDown} target="_blank" href="https://www.baidu.com">
        click
      </a>
    </>
  );
}

export { Demo1 };
