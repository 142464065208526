import { useLayoutEffect, useState } from 'react';

function Scene2() {
  const [refer, setRefer] = useState('');
  useLayoutEffect(() => {
    const referer = document.referrer;
    setRefer(referer);
  }, []);

  return (
    <>
      <h1>判断 referer</h1>
      <div>
        Refer: <span>{refer}</span>
      </div>
      <button>click</button>
    </>
  );
}

export function Demo1() {
  const [refer, setRefer] = useState('');
  useLayoutEffect(() => {
    const referer = document.referrer;
    setRefer(referer);
  }, []);

  return (
    <>
      <h1>判断 referer</h1>
      <div>
        Refer: <span>{refer}</span>
      </div>
      <button>click</button>
    </>
  );
}
export function Demo2() {
  const [refer, setRefer] = useState('');
  useLayoutEffect(() => {
    const referer = document.referrer;
    setRefer(referer);
  }, []);

  return (
    <>
      <h1>判断 referer</h1>
      <div>
        Refer: <span>{refer}</span>
      </div>
      <button>click</button>
    </>
  );
}
export function Demo3() {
  const [refer, setRefer] = useState('');
  useLayoutEffect(() => {
    const referer = document.referrer;
    setRefer(referer);
  }, []);

  return (
    <>
      <h1>判断 referer</h1>
      <div>
        Refer: <span>{refer}</span>
      </div>
      <button>click</button>
    </>
  );
}
export function Demo4() {
  const [refer, setRefer] = useState('');
  useLayoutEffect(() => {
    const referer = document.referrer;
    setRefer(referer);
  }, []);

  return (
    <>
      <h1>判断 referer</h1>
      <div>
        Refer: <span>{refer}</span>
      </div>
      <button>click</button>
    </>
  );
}

export default Scene2;
