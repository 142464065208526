import './index.scss';

const size = 50;
const gap = 50;
const count = 10;

export default function Layout() {
  return (
    <div className="page-scroll">
      <div className="container">
        {new Array(count).fill(0).map((_, row) => {
          return new Array(count).fill(0).map((_, col) => {
            return (
              <div
                className="block"
                id={`block_${row}_${col}`}
                style={{
                  left: col * (gap + size),
                  top: row * (gap + size),
                }}
              >
                {row},{col}
              </div>
            );
          });
        })}
      </div>

      {new Array(count * 2).fill(0).map((_, index) => {
        return <div className="block">{index}</div>;
      })}
    </div>
  );
}
