import storage from '@/storage';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { SafeJsonType } from 'safe-json-type';
import ImportCDN, { ImportCSS } from '../../lib/ImportCDN';
import './index.scss';
export default function Layout() {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const userInfo = SafeJsonType.parse(storage.get('userInfo') || '');
      if (!userInfo) {
        navigate('/login');
        return;
      }
    } catch (error) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const baseUrl = storage.get('baseUrl');
    const sdkUrl = storage.get('sdkUrl');
    const projectId = storage.get('projectId');

    if (baseUrl && projectId) {
      ImportCDN(`${sdkUrl}/main.js`, 'ImeanSDK').then((ImeanSDK: any) => {
        ImeanSDK.init({
          // 没有打开帮助中心功能或者打开了用户历程功能，都隐藏帮助中心按钮
          hideHelp: false,
          // 项目id
          projectId,
          // 返回用户信息
          identity: function () {
            const userInfo = SafeJsonType.parse(storage.get('userInfo') || '');
            delete userInfo.baseUrl;
            delete userInfo.projectId;
            return userInfo;
          },
          // SDK接口地址
          baseUrl,
        }).then(() => {
          console.log('ImeanSDK init done');
          // ImeanSDK.replay({ id: "pZV9-HWpoYKxw5zkqHdhR" });
        });
      });
      ImportCSS(`${sdkUrl}/main.css`);
    }
  }, []);

  return (
    <div className="component-layout">
      <div className="container">
        <Outlet></Outlet>
      </div>
    </div>
  );
}
