type keys = 'userInfo' | 'projectId' | 'baseUrl' | 'sdkUrl';

const storage = {
  get: (key: keys) => {
    return window.localStorage.getItem(prefixKey(key));
  },
  set: (key: keys, value: string) => {
    window.localStorage.setItem(prefixKey(key), value);
  },
  del: (key: keys) => {
    window.localStorage.removeItem(prefixKey(key));
  },
  clear: () => {
    window.localStorage.clear();
  },
};
export const prefixKey = (key: string) => {
  return 'imean-user-segmentation-' + key;
};
export default storage;
